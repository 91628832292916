export const QuickResponder = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="32" fill="#EDEDED" />
      <path
        d="M44.5003 23.6987V37.9874H41.621C41.4368 37.9874 41.2601 38.0606 41.1298 38.1909C40.9996 38.3211 40.9264 38.4978 40.9264 38.6821V43.4577C40.9258 43.5946 40.8847 43.7284 40.8084 43.8421C40.732 43.9558 40.6237 44.0444 40.4972 44.0967C40.3706 44.149 40.2314 44.1628 40.097 44.1362C39.9627 44.1096 39.8392 44.044 39.742 43.9474L33.9869 38.1923C33.9223 38.1273 33.8455 38.0758 33.7608 38.0406C33.6762 38.0054 33.5854 37.9873 33.4938 37.9874H26.6377L30.0067 34.6184C30.0716 34.554 30.1486 34.5031 30.2332 34.4685C30.3178 34.434 30.4085 34.4165 30.4999 34.417H40.2248C40.409 34.417 40.5857 34.3438 40.716 34.2135C40.8462 34.0833 40.9194 33.9066 40.9194 33.7223V23.6987H44.5003Z"
        fill="#C41719"
      />
      <path
        d="M23.0635 23.6989H37.3557M23.0635 27.2728H37.3557M23.0635 30.8467H37.3557M40.9261 20.8196V33.7225C40.9261 33.9068 40.8529 34.0834 40.7226 34.2137C40.5924 34.344 40.4157 34.4172 40.2314 34.4172H30.5065C30.4151 34.4166 30.3245 34.4341 30.2398 34.4687C30.1552 34.5033 30.0782 34.5542 30.0133 34.6186L24.2583 40.3737C24.1613 40.4714 24.0375 40.5382 23.9026 40.5654C23.7676 40.5926 23.6276 40.5792 23.5003 40.5267C23.373 40.4742 23.2642 40.3851 23.1877 40.2707C23.1111 40.1562 23.0703 40.0216 23.0704 39.884V35.1118C23.0704 34.9276 22.9972 34.7509 22.867 34.6206C22.7367 34.4903 22.56 34.4172 22.3758 34.4172H20.1946C20.0104 34.4172 19.8337 34.344 19.7035 34.2137C19.5732 34.0834 19.5 33.9068 19.5 33.7225V20.8196C19.5 20.6354 19.5732 20.4587 19.7035 20.3285C19.8337 20.1982 20.0104 20.125 20.1946 20.125H40.2384C40.4226 20.125 40.5993 20.1982 40.7296 20.3285C40.8598 20.4587 40.933 20.6354 40.933 20.8196H40.9261ZM44.5 23.6989V37.9876H41.6207C41.4365 37.9876 41.2598 38.0608 41.1295 38.191C40.9993 38.3213 40.9261 38.498 40.9261 38.6822V43.4579C40.9255 43.5948 40.8845 43.7286 40.8081 43.8423C40.7317 43.956 40.6235 44.0445 40.4969 44.0969C40.3703 44.1492 40.2311 44.163 40.0967 44.1364C39.9624 44.1098 39.8389 44.0441 39.7417 43.9476L33.9867 38.1925C33.922 38.1275 33.8452 38.0759 33.7605 38.0408C33.6759 38.0056 33.5851 37.9875 33.4935 37.9876H26.6374L30.0064 34.6186C30.0713 34.5542 30.1483 34.5033 30.2329 34.4687C30.3175 34.4341 30.4082 34.4166 30.4996 34.4172H40.2245C40.4087 34.4172 40.5854 34.344 40.7157 34.2137C40.846 34.0834 40.9191 33.9068 40.9191 33.7225V23.6989H44.5Z"
        stroke="black"
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FastShipper = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="32" fill="#EDEDED" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5833 34.0312H18.25V22.7639C18.25 22.5613 18.3305 22.367 18.4737 22.2237C18.617 22.0805 18.8113 22 19.0139 22H35.8194C36.022 22 36.2163 22.0805 36.3596 22.2237C36.5028 22.367 36.5833 22.5613 36.5833 22.7639V34.0312Z"
        fill="#C41719"
      />
      <path
        d="M37.6872 38.3281H36.5833M36.5833 25.4375H41.9306C42.045 25.4376 42.1579 25.4633 42.2611 25.5129C42.3642 25.5625 42.4548 25.6346 42.5264 25.724L45.5819 29.5434C45.6906 29.6788 45.7499 29.8472 45.75 30.0208V37.5795C45.75 37.7821 45.6695 37.9764 45.5263 38.1197C45.383 38.2629 45.1887 38.3434 44.9861 38.3434H42.5455M39.6389 25.4528V29.8451C39.6389 30.0477 39.7194 30.242 39.8626 30.3853C40.0059 30.5285 40.2002 30.609 40.4028 30.609H45.75M36.5833 34.0313H18.25V22.7639C18.25 22.5613 18.3305 22.367 18.4737 22.2237C18.617 22.0805 18.8113 22 19.0139 22H35.8194C36.022 22 36.2163 22.0805 36.3596 22.2237C36.5028 22.367 36.5833 22.5613 36.5833 22.7639V34.0313ZM36.5833 34.0313V38.3281H24.1167C23.9401 37.8248 23.6115 37.3887 23.1763 37.0802C22.7412 36.7717 22.2209 36.606 21.6875 36.606C21.1541 36.606 20.6338 36.7717 20.1987 37.0802C19.7635 37.3887 19.4349 37.8248 19.2583 38.3281H19.0139C18.8113 38.3281 18.617 38.2476 18.4737 38.1044C18.3305 37.9611 18.25 37.7668 18.25 37.5642V34.0313H36.5833ZM24.2656 39.2028C24.2656 39.8865 23.994 40.5423 23.5105 41.0258C23.027 41.5093 22.3713 41.7809 21.6875 41.7809C21.0037 41.7809 20.348 41.5093 19.8645 41.0258C19.381 40.5423 19.1094 39.8865 19.1094 39.2028C19.1075 38.9097 19.158 38.6187 19.2583 38.3434C19.4349 37.84 19.7635 37.404 20.1987 37.0955C20.6338 36.787 21.1541 36.6213 21.6875 36.6213C22.2209 36.6213 22.7412 36.787 23.1763 37.0955C23.6115 37.404 23.9401 37.84 24.1167 38.3434C24.217 38.6187 24.2675 38.9097 24.2656 39.2028V39.2028ZM42.6944 39.2028C42.6957 39.8497 42.454 40.4734 42.0173 40.9507C41.5806 41.4279 40.9807 41.7239 40.3362 41.7799C39.6918 41.836 39.0498 41.6481 38.5372 41.2534C38.0247 40.8588 37.679 40.2861 37.5685 39.6487C37.458 39.0113 37.5908 38.3556 37.9406 37.8115C38.2904 37.2673 38.8318 36.8743 39.4575 36.7102C40.0833 36.5461 40.7478 36.6229 41.3196 36.9253C41.8914 37.2278 42.3289 37.7338 42.5455 38.3434C42.6436 38.6193 42.694 38.9099 42.6944 39.2028V39.2028Z"
        stroke="black"
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0622 25.5942L20.9316 29.555H21.898L22.998 25.5981L22.0622 25.5942Z"
        fill="white"
      />
      <path
        d="M24.5673 25.602L23.4749 26.8051L23.093 25.5981L22.6729 27.0113L22.9899 28.4245L24.1586 27.0152L24.5673 25.602Z"
        fill="white"
      />
      <path
        d="M24.4761 29.5623L25.6028 25.6016H24.6327L23.5098 29.5585L24.4761 29.5623Z"
        fill="white"
      />
      <path
        d="M26.6185 29.5701L27.7147 29.5739L27.2258 27.4655L28.7612 25.6131L27.8407 25.6093L27.0196 26.6176L26.7866 25.6055H25.698L26.1564 27.5878L23.1963 31.1055H24.1321L26.3626 28.4815L26.6185 29.5701Z"
        fill="white"
      />
      <path
        d="M25.5334 29.792H25.3692L25.1133 30.7048H25.7053L25.7358 30.5712L25.3119 30.5635L25.5334 29.792Z"
        fill="white"
      />
      <path
        d="M26.2522 30.4906L26.1758 30.5555H25.9734L25.9275 30.4982L26.0689 30.0017L26.1414 29.9444H26.34L26.3935 30.0017L26.2522 30.4906ZM26.4546 29.7954H26.1109L25.9237 29.9329L25.748 30.5631L25.8626 30.7121H26.1949L26.3935 30.5555L26.5692 29.9367L26.4546 29.7954Z"
        fill="white"
      />
      <path
        d="M26.9736 30.3683L26.943 30.5058L26.8704 30.5593L26.6794 30.5555L26.6374 30.4906L26.7711 30.0131L26.8513 29.9482H27.0461L27.0805 30.0131L27.0538 30.1201L27.218 30.1239L27.2638 29.9291L27.1569 29.7954H26.8322L26.626 29.952L26.4541 30.5478L26.5801 30.7159H26.8972L27.0881 30.5631L27.1378 30.3722L26.9736 30.3683Z"
        fill="white"
      />
      <path
        d="M28.0269 29.7992H27.8512L27.4349 30.1659L27.5342 29.7992L27.3662 29.7954L27.1064 30.7159H27.2821L27.37 30.3989L27.5266 30.2614L27.6144 30.7197H27.7748L27.6603 30.1315L28.0269 29.7992Z"
        fill="white"
      />
      <path
        d="M28.1078 30.2992H28.4134L28.4439 30.1769H28.1384L28.1995 29.9516H28.6426L28.6846 29.7988H28.0926L27.8252 30.7193H28.4249L28.4669 30.5665H28.0353L28.1078 30.2992Z"
        fill="white"
      />
      <path
        d="M28.7957 30.1929L28.8607 29.9561H29.1013L29.1357 29.9981L29.1051 30.1394L29.0402 30.2005L28.7957 30.1929ZM29.2502 30.1929L29.319 29.9332L29.2235 29.8071H28.7499L28.4863 30.7276H28.6468L28.7537 30.3457H28.8644L28.9256 30.7276H29.0898L29.0249 30.3457H29.0707L29.2502 30.1929Z"
        fill="white"
      />
    </svg>
  );
};

export const AccurateDescriber = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="32" fill="#EDEDED" />
      <path
        d="M22 23.4094H26.3879C26.5905 23.4094 26.7848 23.329 26.928 23.1857C27.0712 23.0425 27.1517 22.8482 27.1517 22.6457V18.2539"
        fill="#C41719"
      />
      <path
        d="M22 23.4094H26.3879C26.5905 23.4094 26.7848 23.3289 26.928 23.1856C27.0712 23.0424 27.1517 22.8481 27.1517 22.6456V18.2538M25.4332 32.0019H39.1813M25.4332 28.5649H39.1813M25.4332 35.4389H39.1813M25.4332 38.876H39.1813M42.6221 19.0176V44.9862C42.6221 45.1888 42.5417 45.3831 42.3984 45.5263C42.2552 45.6695 42.0609 45.75 41.8584 45.75H22.7638C22.5612 45.75 22.3669 45.6695 22.2237 45.5263C22.0805 45.3831 22 45.1888 22 44.9862V23.7263C21.9998 23.5246 22.0795 23.3311 22.2215 23.1879L26.9302 18.4753C27.0728 18.3319 27.2664 18.2509 27.4687 18.25H41.8584C42.0609 18.25 42.2552 18.3305 42.3984 18.4737C42.5417 18.6169 42.6221 18.8112 42.6221 19.0138V19.0176Z"
        stroke="black"
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
